import React from 'react'
import TableMui from './TableMui'
import moment from 'moment-timezone'
import getTableOptions from '../constants/TableOptions'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import DialogTable from './DialogTable'
import { hrHistCols, perActHistModalCols } from '../constants/EmployeeCols'
import { makeStyles } from '@material-ui/core/styles'
import ReactTable from '../components/ReactTable'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Grid } from '@material-ui/core'
import {dateFormat} from '../utils/formatter';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0
  },
  padding: {
    padding: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

export default function FieldHistory (props) {
  const classes = useStyles()
  const [isLoading, setLoading] = React.useState(false)
  const [hrHist, setHrHist] = React.useState(null)
  const [objInfo, setObjInfo] = React.useState(null)
  const [fieldIdValue, setFieldIdValue] = React.useState(null)
  const [fieldValue, setFieldValue] = React.useState('')

  const handleClose = () => {
    setObjInfo(null)
  }
  const getFieldData = async event => {
    event.preventDefault()
    if (!fieldIdValue) {
      return null
    }
    setLoading(true)
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'hr-hist',
        empId: props.empId,
        fieldId: fieldIdValue,
        company: props.company
      })
    )
    if (resp.data) {
      const hrHistData = resp.data.hrHist.map(row => {
        return {
          ...row,
          VIEW: row.ACT_OBJ_ID !== 0 ? (
            <div
              className='editLink'
              onClick={() => getPerAction(row.ACT_OBJ_ID)}
            >
              view
            </div>
          ) : (
            <div></div>
          )
          }
      })
      setHrHist(hrHistData)
    }
    setLoading(false)
  }
  const onTagChange = (event, value) => {
    if (value) {
      setFieldIdValue(value.FLD_NBR)
      setFieldValue(value.ITEM_NAME)
    } else {
      setFieldIdValue(null)
      setFieldValue('')
    }
  }
  const getPerAction = async objId => {
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'per_act',
        objId,
        empId: props.empId,
        company: props.company
      })
    )

    if (resp.success) {
      setObjInfo(resp.data.personalActDetail)
    } else {
      setObjInfo(null)
    }
  }
  if (!props.fields) return null

  return (
    <div className={classes.root}>
      <DialogTable
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={perActHistModalCols}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={e => getFieldData(e)}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Autocomplete
                id='field'
                options={props.fields}
                getOptionLabel={option => option.ITEM_NAME}
                filterSelectedOptions
                autoHighlight
                autoSelect
                onChange={(event, values) => onTagChange(event, values)}
                style={{ width: 300 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin='dense'
                    label='Select Field'
                    variant='outlined'
                  />
                )}
              />
              {/* <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Field
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    style={{
                      minWidth: "150px",
                      maxHeight: 300,
                      overflowY: "scroll",
                    }}
                    inputProps={{ id: "field" }}
                    id="demo-simple-select-outlined"
                    defaultValue={props.fields[0] || null}
                    label="Select Field"
                    margin="dense"
                  >
                    {props.fields.map((yr) => (
                      <MenuItem key={yr.FLD_NBR} value={yr.FLD_NBR}>
                        {yr.ITEM_NAME}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}

              <div style={{ marginLeft: '1rem' }}>
                <LoadingButton
                  label='Search'
                  isLoading={isLoading}
                  color='primaryVLButton'
                  buttonType='submit'
                />
              </div>
            </div>
          </form>
        </Grid>
        <Grid item xs={12}>

          {!!hrHist && (
            <ReactTable
              cols={hrHistCols}
              data={hrHist}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
