import React from 'react';
import NumberFormat from 'react-number-format';
import { dateOptions } from '../utils/CustomTableDateFilter';
import {
  currencyFormat,
  dateFormat
} from '../utils/formatter'

const numberRender  = (value, tableMeta, updateValue) => {
  return (
    <NumberFormat
      value={parseFloat(value).toFixed(2)}
      thousandSeparator={true}
      prefix={'$'}
      displayType="text"

    />
  );
}

export const perActHistCols = [
  {
    accessorKey: "ACTION_CODE",
    header: "Action Code",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "EFFECT_DATE",
    header: "Effect Date",
    filterFn: "contains",
    size:1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: "ACTION_NBR",
    header: "Action Number",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "REASON_01",
    header: "Reason",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "REASON_02",
    header: "Reason 2",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "USER_ID",
    header: "User ID",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "ACTION_TYPE",
    header: "Action Type",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "CREATE_USER",
    header: "Create User",
    filterFn: "contains",
    size:1
  },
  {
    accessorKey: "DATE_STAMP",
    header: "Date Stamp",
    filterFn: "contains",
    size:1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: "VIEW",
    header: "View",
    enableSorting: false,
    enableColumnFilter: false,
    size:1,
    dontDownload: true
  }
]


export const perActHistModalCols = [
  {
    name: 'ITEM_NAME',
    label: 'Item Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'VALUE',
    label: 'Value',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BEG_DATE',
    label: 'Begin Date',
    options: {
      filter: true,
      sort: true,
      customBodyRender: dateFormat,
    },
  },
  {
    name: 'USER_ID',
    label: 'User',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DATE_STAMP',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      customBodyRender: dateFormat,
    },
  },
  {
    name: 'RECORD_TYPE',
    label: 'Record Type',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const dedCols = [
  {
    "accessorKey": "Ded",
    "header": "Ded",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "Amount",
    "header": "Amount or Percent",
    "filterFn": "contains"
  },
  {
    "accessorKey": "Type",
    "header": "Type",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "Balance",
    "header": "Balance Amount",
    "filterFn": "contains",
    size:1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "one",
    "header": "1",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "two",
    "header": "2",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "three",
    "header": "3",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "four",
    "header": "4",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "five",
    "header": "5",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "six",
    "header": "6",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "seven",
    "header": "7",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "eight",
    "header": "8",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "nine",
    "header": "9",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "Begin_Date",
    "header": "Begin Date",
    "filterFn": "contains",
    size:1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "End_Date",
    "header": "End Date",
    "filterFn": "contains",
    size:1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "Pty",
    "header": "Pty",
    "filterFn": "contains",
    size:1
  },
  {
    "accessorKey": "Ar",
    "header": "Ar",
    "filterFn": "contains",
    size:1
  }
]

export const payRateHistCols = [
  {
    "accessorKey": "BEG_DATE",
    "header": "Begin Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "PAY_RATE",
    "header": "Pay Rate",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "ANNUAL_SALARY",
    "header": "Salary",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "DATE_STAMP",
    "header": "Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "SALARY_CLASS",
    "header": "Salary Class",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "POS_LEVEL",
    "header": "Position Level",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "ANNUAL_HOURS",
    "header": "Annual Hours",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "NBR_FTE",
    "header": "NBR FTE",
    "filterFn": "contains",
    "size": 1
  }
]


export const wageCols = [
  {
    "accessorKey": "PAY_SUM_GRP",
    "header": "Pay Sum Group",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "DESCRIPTION",
    "header": "Description",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "HOURS",
    "header": "Hours",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "sum_units",
    "header": "Units",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "sum_wage",
    "header": "Wages",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  }
]

export const deductCols = [
  {
    "accessorKey": "DED_CODE",
    "header": "Deduction",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "description",
    "header": "Description",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "PAID_BY",
    "header": "Type",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "sum_ded",
    "header": "Amount",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "sum_wage",
    "header": "Taxable Wages",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  }
]

export const checkCols = [
  {
    "accessorKey": "CHECK_VIEW",
    "header": "Check Id",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "CHECK_NBR",
    "header": "Check Number",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "CHECK_DATE",
    "header": "Check Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "PRINT_TYPE",
    "header": "Print Type",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "CHECK_TYPE",
    "header": "Check Type",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "STATUS",
    "header": "Status",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "GROSS_PAY",
    "header": "Gros Pay",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "NET_PAY_AMT",
    "header": "Net Pay Amount",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "PER_END_DATE",
    "header": "Period End Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "M_CHECK_TYPE",
    "header": "M Check Type",
    "filterFn": "contains",
    "size": 1
  }
]

export const hrHistCols = [
  {
    "accessorKey": "FLD_NBR",
    "header": "Folder Number",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "BEG_DATE",
    "header": "Begin Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "N_VALUE",
    "header": "N Value",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "A_VALUE",
    "header": "A Value",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "D_VALUE",
    "header": "D Value",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "USER_ID",
    "header": "User ID",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "DATE_STAMP",
    "header": "Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "ACTION_CODE",
    "header": "Action Code",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "EFFECT_DATE",
    "header": "Effective Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "REASON_01",
    "header": "Reason 1",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "REASON_02",
    "header": "Reason 2",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "VIEW",
    "header": "View",
    "filterFn": "contains",
    "size": 1
  }
]

export const userFieldCols = [
  {
    accessorKey: 'TYPE',
    header: 'Type',
    filterFn: "contains",
  },
  {
    accessorKey: 'FIELD_NAME',
    header: 'Field Name',
    filterFn: "contains"
  },
  {
    accessorKey: 'VALUE',
    header: 'Value',
    filterFn: "contains"
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    filterFn: "contains"
  },
];

export const groupCols = [
  {
    name: '',
    label: '',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: '',
    label: '',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: '',
    label: '',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true,
    },
  },
];

export const benefitCols = [
  {
    "accessorKey": "PLAN_TYPE",
    "header": "Plan Type",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "PLAN_CODE",
    "header": "Plan Code",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "DISPLAY_DESC",
    "header": "Description",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "FLEX_PLAN",
    "header": "Flex Plan",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "START_DATE",
    "header": "Start Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "STOP_DATE",
    "header": "Stop Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "PLAN_OPTION",
    "header": "Plan Option",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "COV_OPTION",
    "header": "Cov Option",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "COVER_AMT",
    "header": "Cover Amount",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "DEP_COVER_AMT",
    "header": "Dep Cover Amount",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "ANNUAL_AMT",
    "header": "Annual Amount",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  }
]


export const dependentCols = [
  {
    "accessorKey": "rel_code",
    "header": "Relation Code",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "last_name",
    "header": "Last Name",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "first_name",
    "header": "First Name",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "fica_nbr",
    "header": "Fica",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "birthdate",
    "header": "Birth Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "sex",
    "header": "Sex",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "dep_type",
    "header": "Type",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "active_flag",
    "header": "Active",
    "filterFn": "contains",
    "size": 1
  },
  {
    "accessorKey": "death_date",
    "header": "Death Date",
    "filterFn": "contains",
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "VIEW",
    "header": "Benefits",
    "filterFn": "contains",
    "size": 1
  }
]


export const dependentBenefitCols = [
  {
    name: 'COVER_TYPE',
    label: 'Cover Type',
    options: {
      filter: true,
      sort: true,
    },
  },
{
    name: 'DISPLAY_DESC',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'EMP_START',
    label: 'Employee Start',
    options: {
      filter: false,
      sort: true,
      customBodyRender: dateFormat,
    },
  },
  {
    name: 'PLAN_CODE',
    label: 'Plan Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'PLAN_TYPE',
    label: 'Plan Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'START_DATE',
    label: 'Start Date',
    options: {
      filter: false,
      sort: true,
      customBodyRender: dateFormat,
    },
  },
  {
    name: 'STOP_DATE',
    label: 'Stop Date',
    options: {
      filter: false,
      sort: true,
      customBodyRender: dateFormat,
    },
  },
];


export const prDistirbCols = [
  {
    "accessorKey": "COMPANY",
    "header": "Company",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "EMPLOYEE",
    "header": "Employee",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "EMPLOYEE_NAME",
    "header": "Name",
    enableSorting: false,
    enableColumnFilter: true,
    "size": 1
  },
  {
    "accessorKey": "CHECK_NBR",
    "header": "Check Number",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "STATUS",
    "header": "Status",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "RECORD_TYPE",
    "header": "Type",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "DIST_COMPANY",
    "header": "Distrib Co",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "DIST_ACCOUNT",
    "header": "Acct",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "DED_CODE",
    "header": "Code",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "DIST_AMT",
    "header": "Amt",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "GL_DATE",
    "header": "Date",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  }
]
