import React, {useEffect, useState} from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, IconButton } from '@mui/material'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import { ThemeProvider, createTheme } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { CommentsDisabledOutlined, DisplaySettingsRounded } from '@mui/icons-material'

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true
}

const csvExporter = new ExportToCsv(csvOptions)

export default function ReactTable (props) {

  const data = props.data
  ? props.data.map((row,index) => {
      Object.keys(row).forEach(function (key, index) {
        if (row[key] === null) {
          row[key] = ''
        }
      })

      return {id: index, ...row}
    })
  : []

  const [rows, setRows] = useState(() => [...data]);
  const [columnFilters, setColumnFilters] = useState([]);

  useEffect(() => {
        if (!!columnFilters.length) {
          let filteredRows = [...data];
          columnFilters.map((filter) => {
            const { id, value } = filter;
            filteredRows = filteredRows.filter((row) => {
              return row[id].toString().toLowerCase().includes(value);
           });
         });
         setRows(filteredRows);
       } else {
         setRows([...data]);
        }
     }, [columnFilters]);

  const defaultMaterialTheme = createTheme();
  const handleExportRows = rows => {
    const displayRows = rows.map((row) => {
      Object.keys(row.original).forEach(header => {
        if(typeof row.original[header] === 'object'){
          row.original[header] = ''
        }
      })
     return  row.original
    })

     csvExporter.generateCsv(displayRows);
  }
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
    <MaterialReactTable
      columns={props.cols}
      data={rows}
      initialState={{ showColumnFilters: false, density: 'compact' }}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      enableStickyHeader
      manualFiltering
      onColumnFiltersChange={setColumnFilters}
      enableFullScreenToggle={false}
      showSkeletons={true}
      enableFilterRankedResults={false}
      enableGlobalFilter={false} //turn off a feature
      state={{
        isLoading: props.isLoading ? true : false,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton
            onClick={() => {
              handleExportRows(table.getPrePaginationRowModel().rows)
            }}
            icon={<CloudDownloadIcon />}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
    />
     </ThemeProvider>
  )
}
