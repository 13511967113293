import React, { useState } from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import getTableOptions from '../constants/TableOptions';
import DialogTable from './DialogTable';
import { dependentCols, dependentBenefitCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';
import ReactTable from '../components/ReactTable'

export default function Dependents(props) {
	const [state, setState] = useState({
		open: false,
		dependent: null,
		title: '',
	});

	const handleClose = () => {
		setState({ ...state, open: false, dependent: null, title: '' });
	};
	if (!props.data) return null;

	const options = getTableOptions({cols: dependentCols, fileName: props.fileName});

	const tableData = props.data.map((d) => {
		return {
			...d,
			VIEW: <div
				className="editLink"
				onClick={() =>
					setState({ ...state, open: true, dependent: d.seq_nbr, title: `${d.first_name} ${d.last_name}` })
				}
			>
				view
			</div>
			}
	});

	const dependentsBenefitTableData = props.dependentBenefitsData
		.filter((plan) => plan.DEPENDENT === state.dependent).sort((a,b) => new Date(b.START_DATE) - new Date(a.START_DATE))
		.map((plan) => plan);
	return (
		<div>
			<DialogTable
				isOpen={!!state.open}
				tableTitle={state.title}
				handleClose={handleClose}
				tableData={dependentsBenefitTableData}
				cols={dependentBenefitCols}
				fileName={`${props.fileName}_${state.title.replaceAll(' ','_')}`}
			/>
			<ReactTable cols={dependentCols} data={tableData} />
		</div>
	);
}
