import React from 'react';
import { payRateHistCols } from '../constants/EmployeeCols';
import ReactTable from '../components/ReactTable'

export default function PayRateHistory(props) {
  if (!props.data) return null;

  return (
    <ReactTable cols={payRateHistCols} data={props.data}/>
  );
}
