import React, { useState } from 'react'
import {
  IconButton,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import { poCols,poWithVendorCols } from '../constants/VendorCols'
import PoDetail from './PoDetail'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import { smallScreenWidth } from '../constants/AppConstants'
import PoDetailDialog from './PoDetailDialog'
import ReactTable from '../components/ReactTable'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

export default function VendorPos (props) {
  const { credentials } = props.fetchInitialData
  const classes = useStyles({ primaryColor: credentials.primaryAppColor })

  const [state, setstate] = useState({
    open: false,
    poHeaderData: null,
    poDetailData: null,
    fetched: false
  })

  const [headers, setHeaders] = useState({
    poNum: null,
    company: null,
    code: null
  })
  const getPo = async (id, comp, code, release) => {

    try {
      if (!id || !comp) return null
      setstate({...state, open: true})

      const resp = await LambdaFetch(
        'po',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-po',
          company: comp,
          po: id,
          code: code,
          release: release
        }),
        '',
        credentials
      )

      if(!resp) throw new Error('No Po')

      setstate({
        ...state,
        poHeaderData: resp.data.poHeader[0],
        poDetailData: resp.data.poDetail,
        poReceive: resp.data.poReceive,
        poReleases: resp.data.poReleases,
        open: true
      })
    } catch (e) {
      console.log(e)
    }
  }
  const updateRelease = release => {
    getPo(headers.poNum, headers.company, headers.code, release)
  }
  
  const options = getTableOptions({ cols: props.showVendor ? poWithVendorCols : poCols, fileName: props.fileName })

  const { poHeaderData, poDetailData, open, poReceive, poReleases, } = state
  const pos = props.data.map(p => {
    return {
      ...p,
      view: (
        <div className='editLink' onClick={() => getPo(p.PO_NUMBER, p.COMPANY, p.PO_CODE)}>
          view
        </div>
      )
    }
  })

  return (
    <>
      <PoDetailDialog
        {...props}
        open={open}
        poHeaderData={poHeaderData}
        poDetailData={poDetailData}
        poReceive={poReceive}
        poReleases={poReleases}
        updateRelease={updateRelease}
        close={() =>
          setstate({ ...state, poHeaderData: null, poDetailData: null, open: false })
        }
      />

      <ReactTable cols={props.showVendor ? poWithVendorCols : poCols} data={pos}/>
    </>
  )
}
