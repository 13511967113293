import React, { useState } from 'react'
import {
  IconButton,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PoDetail from './PoDetail'
import { makeStyles } from '@material-ui/core/styles'
import { smallScreenWidth } from '../constants/AppConstants'
import LoadingCircle from '../components/common/LoadingCircle'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

export default function PoDetailDialog (props) {
  const { credentials } = props.fetchInitialData
  const classes = useStyles({ primaryColor: credentials.primaryAppColor })
  const { poHeaderData, poDetailData, open, poReceive,poReleases, updateRelease } = props
  const closeDialog = () => {
    props.close()
  }
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth='xl'
        fullScreen={
          props.fetchInitialData.credentials.appWidth < smallScreenWidth
            ? true
            : false
        }
        scroll='body'
        keepMounted
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => closeDialog()}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent style={{ padding: 0 }}>
          {poHeaderData ? (
            <PoDetail
              {...props}
              poHeaderData={poHeaderData}
              poDetailData={poDetailData}
              poReceive={poReceive}
              poReleases={poReleases}
              updateRelease={updateRelease}
              inverseElevation
            />
          ) : (
            <div style={{ width: window.innerWidth * 0.9, height: window.innerHeight *.8}}>
              <LoadingCircle />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
